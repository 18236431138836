// import { BaseCardType } from "@/graphql/4 - widgetsDTO/models/BaseCard.model";
import { BreakingApertureResponseType } from "@/app/types/Breaking";

export const getBreakingDTO = (data: any, isCategoryTitle: boolean): any => { // TODO: DEBE SER BaseCardType
	const parsedData: BreakingApertureResponseType = data;
	return {
		isNull: parsedData ? (parsedData?.isNull ? true : false) : true,
		topicUrl:
			parsedData?.relationships?.field_general_theme?.data?.attributes
				?.field_enlace,
		topic:
			parsedData?.relationships?.field_general_theme?.data?.attributes?.name,
		isBreakingNews: parsedData?.attributes?.field_last_hour,
		breakingTitle: isCategoryTitle
			? parsedData?.name
			: parsedData?.attributes?.field_titulo_apertura,
		breakingDescription: isCategoryTitle
			? parsedData?.description
			: parsedData?.attributes?.field_descripcion_apertura,
		breakingTag: parsedData?.attributes?.field_last_hour
			? parsedData?.attributes?.field_text_break_news
			: "",
	};
};
