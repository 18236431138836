"use client";

import React, { ReactNode, useEffect, useRef, useState } from "react";

export default function RenderIframe({
	src,
	width = "100%",
	id,
	height = "500px",
}: {
	src: string;
	width?: string;
	id?: string;
	height?: string;
}): ReactNode {
	const iframeRef = useRef<HTMLIFrameElement>(null);
	const [iframeHeight, setIframeHeight] = useState<number>(-1);

	useEffect(() => {
		const handleResize = (event: MessageEvent) => {
			if (event.source === iframeRef.current?.contentWindow) {
				if (event.data.height) {
					iframeRef.current.style.height = `${event.data.height}px`;
					setIframeHeight(event.data.height);
				}
			}
		};

		window.addEventListener("message", handleResize);
		return () => window.removeEventListener("message", handleResize);
	}, []);

	return (
		<iframe
			ref={iframeRef}
			src={src}
			width={width}
			id={id}
			height={height}
			allowFullScreen
		/>
	);
}
