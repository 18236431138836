export const RenderAdsScript = new Promise((resolve) => {
	if (
		typeof window !== "undefined" &&
		window?.location?.href?.includes("/preview/")
	)
		return;

	if (typeof window === "undefined") {
		resolve(true);
		return;
	}

	const loadScript = () => {
		const isRenderScript = document.head.querySelector(
			"script[id='AdsScript']"
		);

		if (isRenderScript === null) {
			const script = document.createElement("script");
			script.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
			script.type = "text/javascript";
			script.id = "AdsScript";
			script.async = true;

			script.onload = () => {
				clearTimeout(gptTimeOut);
				resolve(true);
			};
			const gptTimeOut = setTimeout(() => {
				document.head.appendChild(script);
			}, 2000);
		} else {
			resolve(true);
		}
	};

	if (typeof window.requestIdleCallback === "function") {
		window.requestIdleCallback(loadScript);
	} else if (
		document.readyState === "complete" ||
		document.readyState === "interactive"
	) {
		loadScript();
	} else {
		window.addEventListener("DOMContentLoaded", loadScript);
	}
});
