type Props = {
	text?: string;
	after?: number;
};

const showTooltipAfter = ({ text = "", after = 0 }: Props) => {
	return text?.length > after ? text : "";
};

export default showTooltipAfter;
