"use client";
import { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import Image from "next/image";
import style from "@/app/styles/Molecules/EmbedPlayer.module.scss";
import "@/app/styles/Molecules/StoryPageVerticalVideo.scss";
const PlayerVodV2 = dynamic(
	() => import("@/app/components/molecules/PlayerVodV2/PlayerVodV2")
);
const PlayerIcon = dynamic(
	() => import("@/app/components/molecules/PlayerIcon/PlayerIcon")
);


type Props = {
	videoCode: string;
	dataValues?: any;
	verticalVideo?: any;
	isliveblog?: boolean;
};

const EmbedPlayer = ({
	videoCode = "",
	dataValues = undefined,
	verticalVideo,
	isliveblog = false,
}: Props) => {
	const [isIos, setIsIos] = useState(false);
	const [isFireFox, setisFirefox] = useState(false);
	const [isPlaying, setPlaying] = useState<boolean>(false);

	const [finalVideoId] = useState<string>(videoCode);
	useEffect(() => {
		setIsIos(
			window?.navigator?.userAgent?.includes("iPhone OS") ||
				window?.navigator?.userAgent?.includes("iPad") ||
				!window?.navigator?.userAgent?.includes("Chrome")
		);
		setisFirefox(window?.navigator?.userAgent?.includes("Firefox"));
	}, []);
	useEffect(() => {
		if (isIos && document.querySelector(".videoVertical") && isPlaying) {
			document.querySelectorAll(".videoVertical").forEach((element) => {
				element.classList.add("jw-vertical-ios");
			});
		} else {
			document.querySelectorAll(".videoVertical").forEach((element) => {
				element.classList.add("jw-vertical-chrome");
			});
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPlaying]);
	useEffect(() => {
		const vidObjScipt = document.createElement("script");
		vidObjScipt.setAttribute("type", "application/ld+json");
		vidObjScipt.setAttribute("id", `vos-${dataValues?.video}`);

		const videoData = {
			"@context": "https://schema.org",
			"@type": "VideoObject",
			name: dataValues?.title,
			description: dataValues?.description,
			thumbnailUrl: dataValues?.src?.includes(".jpg")
				? [dataValues?.src]
				: `${process.env.NEXT_PUBLIC_BASE_URL?.replace(
						/\/+$/,
						""
				  )}/assets/placeholder_.svg`,
			uploadDate: dataValues?.uploadDate,
			duration: dataValues?.duration,
			contentUrl: dataValues?.contentUrl,
			embedUrl: `${process.env.NEXT_PUBLIC_BASE_URL?.replace(
				/\/+$/,
				""
			)}/preview-video/?idvideo=${dataValues?.video}`,
		};
		vidObjScipt.textContent = JSON.stringify(videoData);
		document?.head?.appendChild(vidObjScipt);
	}, [
		dataValues?.contentUrl,
		dataValues?.description,
		dataValues?.duration,
		dataValues?.src,
		dataValues?.title,
		dataValues?.uploadDate,
		dataValues?.video,
	]);
	return finalVideoId !== "" ? (
		<>
			{!dataValues ? (
				<div className={`${style["embedPlayer__vidWrapper"]}`}>
					<PlayerVodV2
						idVideo={finalVideoId}
						autoplay={false}
						mute={false}
						manualPlay={isPlaying}
						currentPlaceholder="/assets/placeholder_.svg"
						isVerticalVideo={verticalVideo}
					/>
				</div>
			) : (
				<div
					className={`${
						style["embedPlayer__nmasVideoWrapper"] +
						" " +
						(isliveblog === verticalVideo ? style.liveblog : "")
					}
						${!isPlaying ? "" : `${style[`embedPlayer__nmasVideoWrapper--playing`]}`}
								${
									isPlaying && verticalVideo
										? `${style["embedPlayer__wrapperVerticalVideo"]}`
										: ""
								}

								${
									verticalVideo &&
									style["embedPlayer__nmasVideoWrapperVertical"] +
										" " +
										(isliveblog ? style.liveblogVer : "")
								}
							`}
				>
					<figure
						onClick={() => {
							setPlaying(true);
						}}
						className={`${
							verticalVideo && style["embedPlayer__verticalVideo"]
						}`}
					>
						<div>
							<div className="nmasVideoIcon">
								<PlayerIcon isTapToPlay/>
							</div>
						</div>
						<Image
							alt={dataValues?.alt}
							src={dataValues?.src}
							width="368"
							height="207"
							loading="lazy"
							placeholder="blur"
							blurDataURL={`${
								process.env.NEXT_PUBLIC_BLURIMAGE ||
								"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4b+YAAAOtAXam2gnIAAAAAElFTkSuQmCC"
							}`}
						/>
					</figure>
					{isPlaying && (
						<PlayerVodV2
							idVideo={finalVideoId}
							autoplay
							mute={false}
							manualPlay={isPlaying}
							cmsid
							currentPlaceholder={
								dataValues?.src ? dataValues?.src : "/assets/placeholder_.svg"
							}
							isVerticalVideo={verticalVideo}
						/>
					)}
				</div>
			)}
		</>
	) : (
		<></>
	);
};

export default EmbedPlayer;
