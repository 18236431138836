const sanitizeUrl = (url: string, regex: RegExp) => {
	return url
		.normalize("NFD")
		.toLowerCase()
		.replace(/\s/g, "-")
		.replace(regex, "");
};
// Extrae el path, usado para los ads
// Casos de uso: /nacional/tacos/una-nota-o-lo-que-sea => /nacional/tacos/
export function saniticePaths(url: string) {
	const segments: string[] = url
		?.split("/")
		?.filter((segment) => segment !== "");
	const validSegments: string[] = [];

	for (const segment of segments) {
		const dashCount = (segment?.match(/-/g) || [])?.length;
		if (dashCount > 4) break;
		validSegments?.push(segment);
	}

	if (
		(validSegments?.length > 0 &&
			validSegments?.[validSegments?.length - 1] === "videos") ||
		validSegments?.[validSegments?.length - 1] === "episodios"
	) {
		validSegments?.pop();
	}

	return validSegments.length > 0 ? `/${validSegments.join("/")}` : "";
}
// Extrae el path para usarlo como categoría según el nivel
// Casos de uso: /nacional/tacos => ["tacos"]
// Casos de uso: /nacional/ => ["nacional"]
export function extractPathLevel(url: string): string[] | null {
	const segments = url?.split("/")?.filter(Boolean);
	if (segments?.length === 0) return null;
	const level = segments?.length >= 3 ? segments?.[1] : segments?.[0];
	return [level?.replace(/-/g, " ")];
}

export default sanitizeUrl;
