"use client";
import {
	FacebookEmbed,
	InstagramEmbed,
	TikTokEmbed,
	XEmbed,
} from "react-social-media-embed";

export default function SocialMediaEmbed({
	url,
	type,
}: {
	url: string;
	type: string;
}) {
	return (
		<>
			{type === "instagram-media" && (
				<InstagramEmbed url={url.replace(/\?.+/, "")} />
			)}
			{type === "twitter" && <XEmbed url={url?.replace(/\?.+/, "")} />}
			{type === "tiktok" && <TikTokEmbed url={url?.replace(/\?.+/, "")} />}
			{type === "facebook-media" && (
				<FacebookEmbed url={url?.replace(/\?.+/, "")} width={500} />
			)}
		</>
	);
}
