import { HeroType } from "@/app/types/HeroType";
import { MinuteToMinuteContentType } from "@/app/types/MinuteCardType";
import { SpecialRelatedCard } from "@/app/types/SpecialRelatedCardType";
import { StoryPageImageElement} from "@/app/types/StoryPageType";
// import { BaseCardType } from "@/graphql/4 - widgetsDTO/models/BaseCard.model"; // TODO: Crear un nuevo type genérico para las cards
import { BaseImageType, BaseVideoType } from "@/app/types/BaseImageType";

export const getIfBreakingWidgetIsVisble = (
	cardItem: any // Debe ser BaseCardType
): boolean => {
	if (
		cardItem.breakingTitle &&
		cardItem.breakingDescription &&
		(cardItem.topic || cardItem.isBreakingNews)
	) {
		return true;
	} else {
		return true;
	}
};

export const notRenderIfNull = (
	cardItem: any, // BaseCardType,
	isLoading: boolean,
	isNotShowIfNull: boolean
): boolean => {
	let isNotRender: boolean = false;

	if (isNotShowIfNull && cardItem.isNull && !isLoading) {
		isNotRender = true;
	}
	return isNotRender;
};
export const notRenderIfNullSpecialHero = (
	cardHero: any,
	isLoading: boolean,
	isNotShowIfNull: boolean
): boolean => {
	let isNotRender: boolean = false;

	if (
		cardHero?.typeMedia === null ||
		(cardHero?.typeMedia === undefined && !isLoading && isNotShowIfNull)
	) {
		isNotRender = true;
	}
	return isNotRender;
};

export const getSkeletonLoading = (
	cardItem: any,
	isLoading: boolean
): boolean => {
	return isLoading || (cardItem?.isNull ?? false);
};

export const isVideoType = (type: string): boolean => {
	return type ? (type.includes("video") ? true : false) : false;
};

const getHour = (dateString: string): string => {
	const date = new Date(dateString);
	return date.toLocaleString("en-US", {
		hour: "2-digit",
		minute: "2-digit",
		hour12: true,
	});
};

export const setTypeMedia = (
	typeMedia: string,
	imagesArray: BaseImageType[],
	videosArray: BaseVideoType[]
): string => {
	const c: string = "Imagen";
	// singleimage || multiimage || autoplay || taptoplay || audio || nomedia
	switch (typeMedia) {
		case "Video":
			return videosArray.length === 1 ? "singlevideo" : "multivideo";
		case "Imagen":
			return imagesArray.length === 1 ? "singleimage" : "multiimage";
		case "Audio":
			return "audio";
		default:
			return "nomedia";
	}
};
export const sanitizeSpecialRedirectUrl = ( path: string ): string => {
	switch (path){
		case '/noticias-locales/programas/las-noticias-guadalajara': return '/guadalajara';
		case '/noticias-locales/programas/las-noticias-monterrey': return '/monterrey';
		default: return path;
	}
}


type Props = {
	cardType?: "hero" | "card" | "storyPage" | "timeline" | "fullsize" | "special" | "vertical";
	data?: any;
	display?: "desktop" | "mobile";
	aspectRatio?: "16:9" | "1:1" | "10:16" | "463Desktop";
};

export const getImageCrop = ({ cardType = "hero", data, display = "desktop", aspectRatio = "16:9" }: Props) => {
	// --- HERO
	if (cardType === "hero") {
		const parsedData: HeroType = data;
		const route = parsedData?.relationships?.field_imagen_story?.attributes?.image_style_uri;
		
		return getAspectRatio(aspectRatio, display, route);

		// ---CARD
	} else if (cardType === "card") {
		const parsedData: HeroType = data;
		const route = parsedData?.relationships?.field_imagen_story?.attributes?.image_style_uri;
		return getAspectRatio(aspectRatio, display, route, false, "medium");
		// ---STORYPAGE
	} else if (cardType === "storyPage") {
		const parsedData: StoryPageImageElement = data;
		const route = parsedData?.attributes?.image_style_uri;
		return getAspectRatio(aspectRatio, display, route, true);
		// ---TIMELINE
	} else if (cardType === "timeline") {
		const parsedData: MinuteToMinuteContentType = data;
		const route = parsedData?.timeline?.relational_content?.relationships?.field_imagen_story?.attributes?.image_style_uri;
		return getAspectRatio(aspectRatio, display, route, false, "medium");
	} else if (cardType === "fullsize") {
		const route =
			data?.relationships?.field_imagen_story?.attributes?.image_style_uri;
		return getAspectRatio(aspectRatio, display, route);
	}else if(cardType === "special"){
		const isVideo = isVideoType(data?.type);
		const imagesObj: SpecialRelatedCard = !isVideo ? data?.relationships?.field_imagen_story?.attributes?.image_style_uri : data?.attributes;
		return getAspectRatio(aspectRatio, display, imagesObj, false, "medium")
	}else if(cardType === "vertical"){
		const isVideo = isVideoType(data?.type);
		const {attributes, relationships, type} = data;
		if(isVideo){
			const imgObj:SpecialRelatedCard = {
				field_imagen_240: attributes?.field_imagen_240 ?? '/assets/placeholder_.svg', 
				field_imagen_340: attributes?.field_imagen_340 ?? '/assets/placeholder_.svg'
			}
			return getAspectRatio(aspectRatio, display, imgObj, false);
		}else{
			const displayType = attributes?.field_type_display;
			if(displayType === "Video"){
				const imgObj:SpecialRelatedCard = attributes?.principal_content?.attributes;
				return getAspectRatio(aspectRatio, display, imgObj, false, "medium");
			}else if(displayType === "Imagen"){
				const imgObj:SpecialRelatedCard = relationships?.field_imagen_story?.attributes?.image_style_uri;
				return getAspectRatio(aspectRatio, display, imgObj, false, "medium");
			}else if(displayType === "Audio"){
				const imgObj:SpecialRelatedCard = relationships?.field_background_audio?.attributes?.image_style_uri;
				return getAspectRatio(aspectRatio, display, imgObj, false, "medium");
			}
		}
		// const imagesObj: SpecialRelatedCard = !isVideo ? data?.
	}

};

const getAspectRatio = (
	aspectRatio: "16:9" | "1:1" | "10:16" | "463Desktop",
	display: "desktop" | "mobile",
	route: any,
	isStoryPage?: boolean,
	sqreSize?: "small" | "medium" | "large"
) => {
	if (display === "desktop") {
		if (aspectRatio === "16:9") {
			// Para story page si se trae desktop, demás son tarjetas se trae mobile siempre 
			return isStoryPage === true ? route?.corte_16_9_desktop : route?.corte_16_9_mobile ?? route?.field_imagen_240;
		} else if (aspectRatio === "1:1") {
			if(sqreSize === "small"){
				return route?.corte_1_1_mobile;
			}else if(sqreSize === "medium"){
				return route?.corte_1_1_desktop;
			}else if(isStoryPage){
				return route?.corte_1_1_story_page;
			}
		} else if (aspectRatio === "10:16") {
			// Para story page si se trae desktop, demás son tarjetas se trae mobile siempre
			if(isStoryPage){
				return route?.corte_9_16_story_page;
			}else{
				return route?.corte_10_16_desktop ?? route?.field_imagen_340;
			}
		} else if (aspectRatio === "463Desktop") {
			return route?.image_463x300;
		}
	} else { // MOBILE
		if (aspectRatio === "16:9") {
			return route?.corte_16_9_mobile ?? route?.field_imagen_240;
		} else if (aspectRatio === "1:1") {
			if(sqreSize === "small"){
				return route?.corte_1_1_mobile;
			}else if(sqreSize === "medium"){
				return route?.corte_1_1_desktop ?? route?.field_imagen_240;
			}else if(isStoryPage){
				return route?.corte_1_1_story_page;
			}
			return route?.corte_1_1_mobile;
		} else if (aspectRatio === "10:16") {
			return isStoryPage ? route?.corte_9_16_story_page : route?.corte_10_16_mobile;
		} else if (aspectRatio === "463Desktop") {
			return route?.image_463x300;
		}
	}
};

export const getMonth = (month: number): string => {
	const months = [
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	];
	return months[month] ? months[month] : "";
};

export const getHour12Format = (hour: number, minutes: number): string => {
	const parsedMinutes =
		minutes === 0 ? "00" : minutes < 10 ? "0" + minutes : minutes;
	const parsedHour = hour <= 12 ? hour : hour - 12;
	const meridian = hour <= 12 ? " AM" : " PM";
	return parsedHour < 10
		? "0" + parsedHour + ":" + parsedMinutes + meridian
		: parsedHour < 12
		? parsedHour + ":" + parsedMinutes + meridian
		: parsedHour === 12
		? parsedHour + ":" + parsedMinutes + meridian
		: "";
};

export const parseDateString = (dateString: string): string => {
	const dateObj = new Date(dateString);
	const finalDate =
		getMonth(dateObj.getMonth()) +
		" " +
		dateObj.getDate() +
		", " +
		dateObj.getFullYear() +
		" | " +
		getHour12Format(dateObj.getHours(), dateObj.getMinutes());
	return dateString ? finalDate : "";
};


export const parseDateNormalize = (dateString: string): string => {
	
	const date = new Date(dateString);
	
	return dateString ? date.toISOString() : "";

};

export const countOccurrences = (
	string: string,
	subString: string,
	allowOverlapping: boolean
): number => {
	string += "";
	subString += "";
	if (subString.length <= 0) return string.length + 1;

	let n = 0;
	let pos = 0;
	const step = allowOverlapping ? 1 : subString.length;

	while (true) {
		pos = string.indexOf(subString, pos);
		if (pos >= 0) {
			++n;
			pos += step;
		} else break;
	}
	return n;
};
